<template>
    <div class="navmb">
        <div class="flex flexa">
            <span class="">认证中心</span>
        </div>
    </div>
    <div class="banl">
        <div class="txt">
            <div class="tit">数据库版本兼容性认证查询</div>
            <div class="desc">
                <p>选择对应的数据库产品与版本查询与对应硬件平台/应用软件的兼容性认证</p>
            </div>
        </div>
    </div>
    <div class="m-centerl">
        <ul class="ul-listl1">
            <li v-for="(item, index) in tabList" :key="index" :class="{ act: item.type == param.type }"
                @click="tabToggle(item.type)">
                <div class="con">{{ item.name }}</div>
            </li>
        </ul>
        <div class="m-tabl">
            <div class="m-queryl">
                <div class="g-titl1"><i></i>兼容性认证查询</div>
                <div class="m-selectl">
                    <div class="info">
                        <template v-if="param.type == 1">
                            <div class="item">
                                <div class="ll">选择硬件平台：</div>
                                <div class="rr">
                                    <el-select v-model="param.hardwarePlatform" class="w242" placeholder="选择硬件平台"
                                        clearable>
                                        <el-option v-for="item in hardwarePlatform" :key="item.dictValue"
                                            :label="item.dictLabel" :value="item.dictValue" />
                                    </el-select>
                                </div>
                            </div>
                        </template>
                        <div class="item">
                            <div class="ll">选择系统版本：</div>
                            <div class="rr">
                                <el-cascader v-model="param.operatingSystemId" class="w242" clearable
                                    :props="system.props" :options="system.options" placeholder="请选择系统版本" />
                            </div>
                        </div>
                        <div class="item">
                            <div class="ll">选择产品：</div>
                            <div class="rr">
                                <el-select-v2 v-model="param.productId" placeholder="选择产品版本" class="w242" filterable
                                    clearable :props="{
                                    value: 'id',
                                    label: 'productName'
                                }" :loading="vselect.product.loading" :options="vselect.product.options"
                                    @change="vselect_version" />
                            </div>
                        </div>
                        <div class="item">
                            <div class="ll">选择产品版本：</div>
                            <div class="rr">
                                <el-select-v2 v-model="param.productVersionId" placeholder="选择产品版本" class="w242"
                                    filterable clearable :props="{
                                    value: 'id',
                                    label: 'versionName',
                                }" :loading="vselect.version.loading" :options="versionMapList" />
                            </div>
                        </div>
                    </div>
                    <div class="submit">
                        <button :class="computed_disable ?'btn':'btns'" @click="getsoftauthentication">立即查询</button>
                    </div>
                </div>
            </div>
            <div class="m-resultl">
                <div class="g-titl1"><i></i>查询结果</div>
                <div class="result success" v-if="fetchRes.isAuthentication == 2">
                    <div class="pic">
                        <img :src="utils.loadImg('authentication_success.png')" alt="">
                    </div>
                    <div class="txt">
                        <div class="tit" style="color: #FF6A00">通过认证</div>
                        <div class="desc" style="color: #FF6A00">
                            <p>{{ fetchRes.productName }} {{ fetchRes.productVersionName }} 已在 {{
                fetchRes.hardwarePlatformName }}
                                {{ fetchRes.operatingSystemName }} 上认证</p>
                        </div>
                    </div>
                </div>
                <div v-else-if="fetchRes.isAuthentication == 1" class="result fail">
                    <div class="pic">
                        <img :src="utils.loadImg('authentication_lost.png')" alt="">
                    </div>
                    <div class="txt">
                        <div class="tit" style="color: #535D72;">未通过认证</div>
                        <div class="desc" style="color: #999999;">
                            <p>{{ fetchRes.productName }} {{ fetchRes.productVersionName }} 未在 {{
                fetchRes.hardwarePlatformName }}
                                {{ fetchRes.operatingSystemName }} 上认证</p>
                        </div>
                    </div>
                </div>
                <div class="tips" v-if="fetchRes.isAuthentication == 2">详细认证信息，请参阅 <a href="JavaScript:void(0)"
                        class="a" @click="$router.push({
                name: 'knowledgeInfo',
                params: {
                    id: fetchRes.docId
                },
                state: {
                    force: $route.path
                }
                })">《 {{ fetchRes.docTitle }} (文档编号:{{fetchRes.docNumber}}) 》</a></div>
                <!-- <div class="tips" v-else-if="fetchRes.isAuthentication == 1">关于相关的详细信息, 可以参阅 <a href="JavaScript:void(0)"
                        class="a" @click="$router.push({
                name: 'knowledgeInfo',
                params: {
                    id: fetchRes.docId
                },
                state: {
                    force: $route.path
                }
                })">《 {{ fetchRes.docTitle }} 》</a></div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    setup() {
        return {
            tabList: [
                { type: '1', name: '硬件平台操作系统认证' },
                { type: '2', name: '应用软件版本认证' }
            ],
        }
    },
    computed: {
        ...mapGetters({
            hardwarePlatform: 'cache/getHardwarePlatform',
        }),
        versionMapList() {
            return this.vselect.version.map_options.get(this.param.productId) ?? []
        },
        computed_disable() {
            let param = this.param
            if(param.type==1){
                return param.productId && param.productVersionId && param.hardwarePlatform && param.operatingSystemId
            }else{
                return param.productId && param.productVersionId && param.operatingSystemId
            }
            
        }
    },
    data() {
        return {
            param: {
                hardwarePlatform: '', //硬件平台
                operatingSystemId: '', //操作系统id
                operatingSystemVersionId: '', //操作系统版本id
                productId: '', //产品id
                productVersionId: '', //产品版本id
                type: '1', //认证类型
            },
            vselect: {
                product: {
                    loading: false,
                    options: [],
                    map_options: new Map()
                },
                version: {
                    loading: false,
                    map_options: new Map()
                },
            },
            system: {
                props: {
                    checkStrictly: false,
                    emitPath: true,
                    value: 'id',
                    label: 'title',
                    children: 'list'
                },
                options: [],
            },
            fetchRes: {}
        }
    },
    mounted() {
        this.$store.dispatch('cache/getHardwarePlatformList')
        this.operatingSystemList()
        this.vselect_product()
    },
    methods: {
        tabToggle(type) {
            this.param = {
                hardwarePlatform: '', //硬件平台
                operatingSystemId: '', //操作系统id
                operatingSystemVersionId: '', //操作系统版本id
                productId: '', //产品id
                productVersionId: '', //产品版本id
                type
            }
            this.fetchRes = {}
            this.operatingSystemList()
        },
        async operatingSystemList() {
            try {
                this.system.options = []
                const { records } = await this.$api.base.operatingSystemList({
                    type: this.param.type,
                    pageNumber: 1,
                    pageSize: 2000
                })
                this.system.options = records
            } catch (e) {
                return console.log(e)
            }
        },
        async getsoftauthentication() {
            try {
                let param = this.param
                if (this.computed_disable) {
                    const _build_param = { // 构建参数
                        type: param.type,
                        productId: param.productId,
                        productVersionId: param.productVersionId,
                        operatingSystemId: param.operatingSystemId?.[0],
                        operatingSystemVersionId: param.operatingSystemId?.[1],
                    }
                    if (this.param.type == 1) { // 硬件平台操作系统认证
                        _build_param.hardwarePlatform = this.param.hardwarePlatform
                    }
                    console.log(_build_param, 'paramparamparamparamparam', param.operatingSystemId)

                    this.fetchRes = await this.$api.user.getsoftauthentication(_build_param)
                    console.log(this.fetchRes, 'fetchRes')
                }
                
            } catch (e) {
                return console.log(e)
            }
        },
        async vselect_product() {
            try {
                this.vselect.product.loading = true
                const { records, total } = await this.$api.bug.productList({
                    type: 1,
                    isAuthentication: 1,
                    pageNumber: 1,
                    pageSize: 1500
                })
                this.vselect.product.map_options = new Map(
                    records.map(item => [item.id, item])
                )
                this.vselect.product.options = records
                this.vselect.product.loading = false
            } catch (e) {
                this.vselect.product.loading = false
                this.vselect.product.options = []
            }
        },
        async vselect_version(id) {
            try {
                this.param.productVersionId = undefined
                this.vselect.version.map_options.set(
                    id,
                    this.vselect.version.map_options.get(id) ?? (
                        await this.$api.bug.versionList({
                            productId: id,
                            pageNumber: 1,
                            pageSize: 2000
                        })
                    ).records
                )
                this.vselect.version.loading = false
            } catch (e) {
                this.vselect.version.loading = false
                this.vselect.version.options = []
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.banl {
    overflow: hidden;
    height: 334px;
    margin: 0 -20px;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    background: url(../../assets/imgs/authBgy.png) no-repeat;
    background-size: cover;

    .txt {
        position: absolute;
        top: 66px;
        left: 0;
        width: 100%;
        padding-left: 27px;

        .tit {
            font-size: 32px;
            color: #313133;
            line-height: 1.406em;
            overflow: hidden;
            margin-bottom: 25px;
            font-weight: bold;
        }

        .desc {
            font-size: 14px;
            color: #636366;
            line-height: 1.428em;
            overflow: hidden;
        }
    }
}

.m-centerl {
    overflow: hidden;
    background-color: #fff;
    margin-top: -121px;
    padding: 40px 54px 30px 30px;
    z-index: 2;
    position: relative;
}

.ul-listl1 {
    overflow: hidden;
    margin-bottom: 30px;

    li {
        width: 50%;
        float: left;

        .con {
            display: block;
            overflow: hidden;
            height: 42px;
            line-height: 40px;
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
            text-align: center;
            transition: .5s;
            cursor: pointer;
        }

        .con:hover {
            background: #FF6A00;
            border-color: #FF6A00;
            color: #fff;
        }
    }

    li.act .con {
        background: #FF6A00;
        border-color: #FF6A00;
        color: #fff;
    }
}

.m-queryl {
    overflow: hidden;
}

.g-titl1 {
    font-size: 14px;
    color: #313133;
    line-height: 1.428em;
    overflow: hidden;
    padding-left: 12px;
    position: relative;

    i {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 3px;
        height: 14px;
        margin-top: -7px;
        background: #FF6A00;
    }
}

.m-selectl {
    padding-top: 30px;
    padding-bottom: 46px;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 40px;

    .info {
        overflow: hidden;
        margin-bottom: 43px;

        .item {
            overflow: hidden;
            margin-right: 40px;
            margin-top:20px;
            float: left;

            .ll {
                width: 90px;
                float: left;
                font-size: 12px;
                color: #303030;
                line-height: 32px;
                margin-right: 10px;
            }

            .rr {
                overflow: hidden;
            }
        }
    }

    .submit {
        overflow: hidden;
        width: 120px;

        .btn {
            display: block;
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #FF6A00;
            font-size: 14px;
            color: #fff;
            transition: .5s;
            cursor: pointer;
        }

        .btns {
            display: block;
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: rgba(255, 106, 0,.4);        
            font-size: 14px;
            color: #fff;
            transition: .5s;
            cursor: pointer;
        }

        .btn:hover {
            background: #ff8833;
        }
    }
}

.m-resultl {
    overflow: hidden;

    .success {
        background: #FFF6F0;
    }

    .fail {
        background: #F7F7F7;
    }

    .result {
        margin-top: 20px;
        overflow: hidden;
        width: 568px;
        padding: 22px 20px 19px 19px;

        margin-bottom: 28px;

        .pic {
            width: 55px;
            height: 55px;
            line-height: 55px;
            overflow: hidden;
            text-align: center;
            font-size: 0;
            margin-right: 16px;
            float: left;

            img {
                display: inline-block;
                vertical-align: middle;
                max-height: 100%;
                max-width: 100%;
            }
        }

        .txt {
            overflow: hidden;
            padding-top: 4px;

            .tit {
                font-weight: bold;
                font-size: 16px;
                color: #FF6A00;
                line-height: 1.375em;
                overflow: hidden;
                margin-bottom: 8px;
            }

            .desc {
                font-size: 12px;
                color: #FF6A00;
                line-height: 1.416em;
                overflow: hidden;
            }
        }
    }

    .tips {
        font-size: 14px;
        color: #313133;
        line-height: 1.428em;
        overflow: hidden;

        .a {
            color: #337eff;
            transition: .5s;
        }

        .a:hover {
            color: #ff6a00;
        }
    }
}

.m-tabl {
    overflow: hidden;
}
</style>